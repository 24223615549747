import { useAxios } from './../axiosUtil'

const token = localStorage.getItem('token')
const tokenType = localStorage.getItem('tokenType')

/**
 * qna 게시글에 등록된 답변 조회
 * @param {} boardNo qna 게시글 번호
 * @returns
 */
export const readQnaComment = async (boardNo) => {
	// 답글 조회 url
	const commentUrl = `/v1/board/lab-qna/${boardNo}/comment/`
	return await useAxios(commentUrl)
}

/**
 * qna 답변 수정
 * @param {*} boardNo qna 게시글 번호
 * @param {*} commentNo qna 게시글 번호 = 답변 번호
 * @param {*} commentData 수정될 답변 데이터
 */
export const updateQnaComment = async (boardNo, commentNo, commentData) => {
	// 답글 수정 url
	const commentUrl = `/v1/board/lab-qna/${boardNo}/comment/${commentNo}`

	const headerOpt = {
		method: 'PUT',
		headers: {
			Authorization: `${tokenType} ${token}`
		}
	}

	const updatedCommentData = { data: commentData, ...headerOpt }

	return await useAxios(commentUrl, updatedCommentData)
}

/**
 * qna 답변 저장
 * @param {*} boardNo qna 게시글 번호
 * @param {*} commentData 저장될 답변 데이터
 */
export const saveQnaComment = async (boardNo, commentData) => {
	// 답변 추가 url
	const commentUrl = `/v1/board/lab-qna/${boardNo}/comment`

	const headerOpt = {
		method: 'POST',
		headers: {
			Authorization: `${tokenType} ${token}`
		}
	}

	const savedCommendData = { data: commentData, ...headerOpt }

	return await useAxios(commentUrl, savedCommendData)
}

/**
 * qna 답변 삭제
 * @param {*} boardNo qna 게시글 번호
 * @param {*} commentNo 답변 게시글 번호
 */
export const deleteQnaComment = async (boardNo, commentNo) => {
	// 답변 삭제 url
	const commentUrl = `/v1/board/lab-qna/${boardNo}/comment/${commentNo}`

	const headerOpt = {
		method: 'DELETE',
		headers: {
			Authorization: `${tokenType} ${token}`
		}
	}

	const deleteCommentData = { data: boardNo, ...headerOpt }

	return await useAxios(commentUrl, deleteCommentData)
}
