import { useAxios } from './../axiosUtil'

const token = localStorage.getItem('token')
const tokenType = localStorage.getItem('tokenType')

/**
 * QNA 조회
 * @param {*} boardNo QNA 게시글 번호
 */
export const readQna = async (boardNo) => {
	let qnaUrl = ''
	if (boardNo > 0) {
		// QNA 한 건 조회 url
		qnaUrl = `/v1/board/lab-qna/${boardNo}`
	} else {
		// QNA 전체 조회 url
		qnaUrl = '/v1/board/lab-qna'
	}

	return await useAxios(qnaUrl)
}

/**
 * qna 삭제
 * @param {*} boardNo qna 게시글 번호
 * @param {*} commentNo 답변 게시글 번호
 */
export const deleteQna = async (boardNo) => {
	// qna 삭제 url
	const commentUrl = `/v1/board/lab-qna/${boardNo}`

	const headerOpt = {
		method: 'DELETE',
		headers: {
			Authorization: `${tokenType} ${token}`
		}
	}

	const deleteQnaData = { data: boardNo, ...headerOpt }

	return await useAxios(commentUrl, deleteQnaData)
}
