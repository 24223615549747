// INFO: QNA 게시판 리스트
<script>
import { GridComponent, ColumnsDirective, Page, Sort, Toolbar, Search, Filter, CommandColumn } from '@syncfusion/ej2-vue-grids'
import { readQna, deleteQna } from '@/api/admin/qna' // qna 조회, 삭제
import { deleteQnaComment } from '@/api/admin/qnaComment' // qna 답변 삭제

import Dropdown from '@/components/ui/Dropdown.vue'

export default {
  name: 'adminBoardQNAList',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    Dropdown
  },
  data() {
    return {
      currentPage: '', // 현재 페이지 번호
      qnaGrid: '', // qna 그리드
      labNo: '', // 연구실 번호

      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      toolbarOptions: ['Search'],
      commands: [{ buttonOption: { content: '삭제', cssClass: 'e-danger' } }], // 삭제 버튼
      formatoptions: { type: 'dateTime', format: 'y/M/d' },
      qnaData: [],
      persistedData: [],
      searchConditions: [
        { value: 'qnaId', text: '번호' },
        { value: 'title', text: '제목' },
        { value: 'name', text: '작성자' }
      ],
      searchBy: ''
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Search, Filter, CommandColumn]
  },
  mounted() {
    this.setSearchOptions()
    // QNA 그리드
    this.qnaGrid = this.$refs.grid.ej2Instances

    // 파라미터 프로젝트 그리드 페이지 번호
    const page = Number(this.$route.params.page)

    // QNA 전체 데이터 가져오기
    const loadQnaData = async () => {
      const data = await readQna(0)
      this.qnaData = data.items

      this.persistedData = this.qnaData
    }
    loadQnaData()

    // 페이지 번호가 있을 때(프로젝트 보기 -> 목록으로 돌아온 경우) 받아온 페이지 번호로 SET
    if (page) {
      this.qnaGrid.pagerModule.pageSettings.currentPage = page
    } else {
      this.qnaGrid.pagerModule.pageSettings.currentPage = 1
    }
  },
  methods: {
    // ********** 한 행 클릭 시 해당 게시글 보기로 이동 **********
    qnaRowSelected(e) {
      // 그리드 현재 페이지
      this.currentPage = this.qnaGrid.pagerModule.pageSettings.currentPage

      // 한 행 클릭한 타켓이 버튼일 경우 리턴
      if (e.target.matches('button')) return

      this.$router.push({
        path: `/admin/board/qna/qnaCreate/${e.data.qnaId}/${this.currentPage}`
      })
    },

    // ********** Qna 그리드 command 삭제 버튼 **********
    commandClick(e) {
      const target = e.target
      if (target.matches('button.e-danger')) return this.delQna(e)
    },
    // ********** Qna 삭제 동작 **********
    async delQna(e) {
      if (!confirm('QNA와 답변이 모두 삭제됩니다. 정말로 삭제하시겠습니까?')) return

      const boardNo = e.rowData.qnaId

      const qnaResult = await deleteQna(boardNo)
      const commentResult = await deleteQnaComment(boardNo, boardNo)

      if (qnaResult.code) return alert(qnaResult.message)
      if (commentResult.code) return alert(commentResult.message)

      alert('삭제되었습니다.')
      this.qnaData = this.qnaData.filter((item) => item.qnaId !== boardNo)
    },
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
      this.data = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    }
  }
}
</script>

<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h4>QNA 게시판</h4>
  </nav>

  <Dropdown
    class="select-wrapper"
    :options="searchConditions"
    :disabled="false"
    name="zipcode"
    ref="selectOption"
    @selected="selectDropdownHandler"
  ></Dropdown>

  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <!-- 제목 입력 -->

      <div class="">
        <!-- QNA 그리드 -->
        <div class="">
          <ejs-grid
            ref="grid"
            :dataSource="qnaData"
            :allowPaging="true"
            :pageSettings="pageSettings"
            :allowSorting="true"
            :toolbar="toolbarOptions"
            :rowSelected="qnaRowSelected"
            :commandClick="commandClick"
            :actionComplete="actionComplete"
          >
            <e-columns>
              <e-column field="qnaId" headerText="번호" width="120" textAlign="center"></e-column>
              <e-column field="title" headerText="제목" textAlign="left"></e-column>
              <e-column field="name" headerText="작성자" width="140" textAlign="center"></e-column>
              <e-column field="createdAt" headerText="작성일" width="120" :format="formatoptions" type="date" textAlign="center"></e-column>
              <e-column field="updatedAt" headerText="수정일" width="120" :format="formatoptions" type="date" textAlign="center"></e-column>
              <e-column field="manage" headerText="관리" width="100" textAlign="center" :commands="commands" />
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
